@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.not-for-ansatte {
  #main
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  #main input[type="button"]:not(.red),
  #main input[type="submit"]:not(.submit-comment-button):not(.red),
  #main input[type="cancel"]:not(.red),
  #main
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
    background-color: @color-primary;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #head-wrapper {
    background: #ffffff;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        100% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 160px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        .head-logo {
          display: block;
          margin-top: 5%;
          max-height: 160px;
          width: auto;
        }
        img {
          max-height: 80%;
          height: 128px;
          width: auto;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-primary;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child a {
    color: @color-dark;
    background-color: @color-neutral--light;
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border: none;
    }
    &:hover,
    &:focus {
      color: #009c49;
    }
  }
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-green.svg") no-repeat 0 0;
}
table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 0;
    }
  }
  #footer-wrapper {
    border-top: 4px solid @color-secondary;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    *,
    a {
      color: @color-neutral--dark;
    }
    #footers {
     padding-left: 0px;
     background: none;
      padding-top: 20px;
      .footer-grid {
        display: flex;
        flex-direction: column;
        height: 170px;
        row-gap: 40px;
        .partners {
          display: flex;
          column-gap: 30px;
          row-gap: 20px;
          align-items: center;
          height: 75px;
          width: 100%;
          margin-bottom: 20px;
          flex-wrap: wrap;
          .logo.logo-artsdatabanken {
            width: auto;
          }
          img {
            max-height: 75px;
            display: block;
          }
        }
        .contact {
          display: flex;
          column-gap: 30px;
          row-gap: 20px;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          height: 75px;
          width: 100%;
          .label {
            font-weight: 600;
          }
          img,
          a {
            color: @color-link;
            display: block;
            &:hover, &:focus{
              color: @color-light;
            }
          }
          span a {
            display: inline-block;
          }
        }
      }
      .logos {
        display: flex;
        justify-content: flex-start;
        position: relative;
        height: 170px;
        width: 100%;
        align-items: center;
        column-gap: 60px;
        .logo-uio {
          margin-left: -65px;
        }
        img {
          display: block;
        }
      }
      .col-3-5 {
        background: none;
        padding-left: 0;
        width: 75%;
      }
      .col-2-5 {
        width: 25%;
      }
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: none @color-light;
      height: 177px;
      #head {
        height: 130px;
        #header {
          img {
            padding: 0 10px 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .not-for-ansatte:not(.header-context) {
    #footer-wrapper #footers {
      padding-top: 20px;
      padding-left: 15px;
      .footer-grid {
        grid-template-columns: 1fr;
        height: max-content;
        row-gap: 40px;
        .contact {
          flex-wrap: wrap;
          height: 125px;
        }
        .partners {
          height: fit-content;
        }
      }
      .logos {
        height: fit-content;
        flex-wrap: wrap;
        row-gap: 20px;
      }
      .col-2-5.responsible-login {
        padding-left: 14px !important;
      }
      .col-2-5 {
        width: 100%;
      }
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary: #40833a; //green #7DB960;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #c9f2b5;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eaedee;
@color-lines: #f0f0f0;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;